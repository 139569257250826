import React from "react";
import PropTypes from "prop-types";
import LocalizedLink from "../LocalizedLink";

import "./hero.css";

const Hero = ({
  data: { hero_headline: headline, hero_image: image, hero_content: content }
}) => (
  <section className="hero" id="bullit">
    <div className="container hero__container">
      <h1 className="hero__headline">{headline.text}</h1>
      <img className="hero__image" src={image.url} alt={image.alt} />
      <div
        className="hero__content"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
      <LocalizedLink className="hero__scroll" to="/#energy">
        <svg
          className="hero__svg"
          width="43"
          height="25"
          viewBox="0 0 43 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 1.89648L21.6552 21.5517L41.3103 1.89648"
            stroke="white"
            strokeWidth="3.62069"
          />
        </svg>
      </LocalizedLink>
    </div>
  </section>
);

Hero.propTypes = {
  data: PropTypes.shape({
    hero_headline: PropTypes.object.isRequired,
    hero_image: PropTypes.object.isRequired,
    hero_content: PropTypes.object.isRequired
  }).isRequired
};

export default Hero;
