import React from "react";
import PropTypes from "prop-types";

import "./contact.css";

const Contact = ({
  data: {
    contact_headline: headline,
    contact_content: content,
    contact_image: image,
    contact_distribution: distribution
  }
}) => (
  <section className="contact" id="contact">
    <div className="container">
      <div className="contact__content-container">
        <div className="contact__content-left">
          <h2>{headline.text}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.html }} />
        </div>
        {image && image.url && (
          <img className="contact__image" src={image.url} alt={image.alt} />
        )}
      </div>
      <div
        className="contact__distribution"
        dangerouslySetInnerHTML={{ __html: distribution.html }}
      />
    </div>
  </section>
);

Contact.propTypes = {
  data: PropTypes.shape({
    contact_headline: PropTypes.object.isRequired,
    contact_content: PropTypes.object.isRequired,
    contact_image: PropTypes.object.isRequired,
    contact_distribution: PropTypes.object.isRequired
  })
};

export default Contact;
