import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import EmblaCarouselReact from "embla-carousel-react";

import "./products.css";

const Products = ({ data: { products } }) => {
  const [embla, setEmbla] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(index => embla.scrollTo(index), [embla]);
  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla.scrollNext(), [embla]);

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setSelectedIndex(embla.selectedScrollSnap());
      });
    }

    return () => embla && embla.destroy();
  }, [embla]);

  if (products && products.length > 1) {
    return (
      <EmblaCarouselReact
        htmlTagName="section"
        emblaRef={setEmbla}
        options={{
          align: "start",
          loop: true,
          containerSelector: ".products__list"
        }}
        className="products"
      >
        <div className="container" id="bullit-cans">
          <ul className="products__list">
            {products.map(product => (
              <li
                className="products__product-container"
                key={product.product_headline.text}
              >
                <div className="products__product-main">
                  <h2 className="products__headline">
                    {product.product_headline.text}
                  </h2>
                  <div
                    className="products__content"
                    dangerouslySetInnerHTML={{
                      __html: product.product_description.html
                    }}
                  />
                </div>
                <img
                  className="products__image"
                  src={product.product_image.url}
                  alt={product.product_image.alt}
                />
                <div
                  className="products__product-misc"
                  dangerouslySetInnerHTML={{
                    __html: product.product_details.html
                  }}
                />
              </li>
            ))}
          </ul>
          <div className="products__arrows">
            <button
              className="products__arrow products__arrow--prev"
              onClick={scrollPrev}
            >
              <svg
                width="24"
                height="43"
                viewBox="0 0 24 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.655 2L3 21.655l19.655 19.656"
                  stroke="#25639E"
                  strokeWidth="3.621"
                />
              </svg>
            </button>
            <button
              className="products__arrow products__arrow--next"
              onClick={scrollNext}
            >
              <svg
                className="products__svg--rotate"
                width="24"
                height="43"
                viewBox="0 0 24 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.655 2L3 21.655l19.655 19.656"
                  stroke="#25639E"
                  strokeWidth="3.621"
                />
              </svg>
            </button>
          </div>
          <div className="products__dots">
            {products.map((product, index) => (
              <button
                key={index}
                className={`products__dot${
                  index === selectedIndex ? " products__dot--selected" : ""
                }`}
                onClick={() => {
                  scrollTo(index);
                }}
              />
            ))}
          </div>
        </div>
      </EmblaCarouselReact>
    );
  }
  return (
    <section className="products" id="bullit-cans">
      <div className="container">
        {products.map(product => (
          <div
            className="products__product-container"
            key={product.product_headline.text}
          >
            <div className="products__product-main">
              <h2 className="products__headline">
                {product.product_headline.text}
              </h2>
              <div
                className="products__content"
                dangerouslySetInnerHTML={{
                  __html: product.product_description.html
                }}
              />
            </div>
            <img
              className="products__image"
              src={product.product_image.url}
              alt={product.product_image.alt}
            />
            <div
              className="products__product-misc"
              dangerouslySetInnerHTML={{
                __html: product.product_details.html
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

Products.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.array.isRequired
  }).isRequired
};

export default Products;
