import React from "react";
import PropTypes from "prop-types";
import LocalizedLink from "../LocalizedLink";

const DesktopMenu = ({ data, showProductsTab }) => (
  <ul className="header__menu">
    <li className="header__item">
      <LocalizedLink className="header__link" to="/#bullit">
        {data.nav_hero}
      </LocalizedLink>
    </li>
    <li className="header__item">
      <LocalizedLink className="header__link" to="/#ingredients">
        {data.nav_intro}
      </LocalizedLink>
    </li>
    {showProductsTab && (
      <li className="header__item">
        <LocalizedLink className="header__link" to="/#bullit-cans">
          {data.nav_products}
        </LocalizedLink>
      </li>
    )}
    <li className="header__item">
      <LocalizedLink className="header__link" to="/#contact">
        {data.nav_contact}
      </LocalizedLink>
    </li>
  </ul>
);

DesktopMenu.propTypes = {
  data: PropTypes.object.isRequired,
  showProductsTab: PropTypes.bool
};

DesktopMenu.defaultProps = {
  data: {},
  showProductsTab: true
};

export default DesktopMenu;
