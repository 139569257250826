import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "../components/Header";
import DesktopMenu from "../components/Header/DesktopMenu";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Products from "../components/Products";
import Contact from "../components/Contact";

const Index = ({ data: { hero, intro, products, contact } }) => {
  const HAS_PRODUCTS = products.data.products.length > 0;

  return (
    <>
      <Header isFixed showProductsTab={HAS_PRODUCTS}>
        <DesktopMenu />
      </Header>
      <main>
        <Hero data={hero.data} />
        <Intro data={intro.data} />
        {HAS_PRODUCTS && <Products data={products.data} />}
        <Contact data={contact.data} />
      </main>
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    hero: PropTypes.object.isRequired,
    intro: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    contact: PropTypes.object.isRequired
  }).isRequired
};

export default Index;

// Querying data from Prismic
export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    hero: prismicHomepage(lang: { eq: $locale }) {
      data {
        hero_headline {
          text
        }
        hero_image {
          alt
          url
        }
        hero_content {
          html
        }
      }
    }
    intro: prismicHomepage(lang: { eq: $locale }) {
      data {
        intro_headline {
          text
        }
        intro_content {
          html
        }
        intro_image {
          alt
          url
        }
        intro_ingredients {
          intro_ingredient {
            html
          }
        }
      }
    }
    products: prismicHomepage(lang: { eq: $locale }) {
      data {
        products {
          product_headline {
            text
          }
          product_description {
            html
          }
          product_details {
            html
          }
          product_image {
            alt
            url
          }
        }
      }
    }
    contact: prismicHomepage(lang: { eq: $locale }) {
      data {
        contact_headline {
          text
        }
        contact_content {
          html
        }
        contact_image {
          alt
          url
        }
        contact_distribution {
          html
        }
      }
    }
  }
`;
