import React from "react";
import PropTypes from "prop-types";

import "./intro.css";

const Intro = ({
  data: {
    intro_headline: headline,
    intro_content: content,
    intro_image: image,
    intro_ingredients: ingredients
  }
}) => (
  <section className="intro" id="ingredients">
    <div className="container intro__container">
      <div className="intro__content">
        <h2>{headline.text}</h2>
        <div dangerouslySetInnerHTML={{ __html: content.html }} />
      </div>
      <img className="intro__image" src={image.url} alt={image.alt} />
      <ul className="intro__ingredients">
        {ingredients.map((ingredient, index) => (
          <li className="intro__ingredient" key={index}>
            <div
              dangerouslySetInnerHTML={{
                __html: ingredient.intro_ingredient.html
              }}
            ></div>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

Intro.propTypes = {
  data: PropTypes.shape({
    intro_headline: PropTypes.object.isRequired,
    intro_content: PropTypes.object.isRequired,
    intro_image: PropTypes.object.isRequired,
    intro_ingredients: PropTypes.array.isRequired
  })
};

export default Intro;
